import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SingleKurskategori from "../components/kurser/SingleKurskategori"
import Seo from "../components/seo"
export default function category({ data }) {
  const kurser = data.wpKurskategori.kurser.nodes
  const kursKategori = data.wpKurskategori
  return (
    <>
      <Layout>
        <Seo title={kursKategori.name} />
        <div className="h-full w-full">
          <SingleKurskategori kurser={kurser} kursKategori={kursKategori} />
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpKurskategori(id: { eq: $id }) {
      slug
      name
      description
      kurser {
        nodes {
          title
          slug
          id
          uri
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.5)
                }
              }
            }
          }
          kursfakta {
            kurslangd
            materialavgift
            studietakt
            kortText
          }
        }
      }
    }
  }
`
